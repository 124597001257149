// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function show(leadId) {
  return "/dashboard/leads/" + ID.toString(leadId);
}

function edit(leadId) {
  return "/dashboard/leads/" + ID.toString(leadId) + "/edit";
}

var Dashboard = {
  index: "/dashboard/leads",
  show: show,
  edit: edit
};

var confirmed = "/leads/confirmed";

var bareMetal = "/leads/bare_metal";

var zenlayerBareMetal = "/leads/zenlayer_bare_metal";

var enzuBareMetal = "/leads/enzu_bare_metal";

var hivelocityBareMetal = "/leads/hivelocity_bare_metal";

var evocativeBareMetal = "/leads/evocative_bare_metal";

var colocation = "/leads/colocation";

var multicloud = "/leads/multicloud";

export {
  confirmed ,
  bareMetal ,
  zenlayerBareMetal ,
  enzuBareMetal ,
  hivelocityBareMetal ,
  evocativeBareMetal ,
  colocation ,
  multicloud ,
  Dashboard ,
}
/* ID Not a pure module */
